body {
  background-color: #f8f9fa;
}

.navbar {
  margin-bottom: 20px;
}

.container-fluid {
  padding: 20px;
}

.card {
  padding: 20px;
  margin: 10px 0;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  line-height: 1.5;
}

.navbar-nav {
  margin-left: auto; /* Align navigation items to the right */
  padding-right: 15px; /* Add padding to the right */
}

.navbar-brand {
  padding-left: 15px; /* Add padding to the left */
}
